@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
:root {
  font-size: 0.625rem;
  --clr-red: #cf2e38;
  --clr-green: #6d8432;
  --clr-purple: #4e3b8a;
  --clr-primary-30: hsl(183, 100%, 32%);
  --clr-primary-10: hsl(184, 97%, 13%);
  --clr-accent-50: hsl(27, 91%, 55%);
  --clr-accent-20: hsl(30, 12%, 20%);
  --clr-neutral-100: #ffffff;
  --clr-neutral-90: hsl(180, 11%, 95%);
  --clr-neutral-80: hsl(209, 46%, 86%);
  --clr-neutral-15: hsl(208, 16%, 16%);
  --shadow-md: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  --ff-heading: "Montserrat", cursive;
  --ff-body: "Montserrat", cursive;
  --fs-900: 3rem;
  --fs-700: 2.4375rem;
  --fs-600: 1.5625rem;
  --fs-500: 1.25rem;
  --fs-400: 1rem;
  --fs-300: 0.9375rem;
  --fs-200: 0.8125rem;
  --lh-125: 1.25;
  --lh-150: 1.5;
  --lh-175: 1.75;
}

@media only screen and (min-width: 1366px) {
  :root {
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1600px) {
  :root {
    font-size: 1rem;
  }
}
.fw-400 {
  font-weight: 500;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.text-primary-30 {
  color: var(--clr-primary-30);
}

.text-primary-10 {
  color: var(--clr-primary-10);
}

.text-accent-50 {
  color: var(--clr-accent-50);
}

.text-accent-20 {
  color: var(--clr-accent-20);
}

.text-neutral-80 {
  color: var(--clr-neutral-80);
}

.text-neutral-15 {
  color: var(--clr-neutral-15);
}

.text-center {
  text-align: center;
}

.lh-125 {
  line-height: var(--lh-125);
}

.lh-150 {
  line-height: var(--lh-150);
}

.lh-175 {
  line-height: var(--lh-175);
}

.fs-900 {
  font-size: var(--fs-900);
}

.fs-700 {
  font-size: var(--fs-700);
}

.fs-600 {
  font-size: var(--fs-600);
}

.fs-500 {
  font-size: var(--fs-500);
}

.fs-400 {
  font-size: var(--fs-400);
}

.fs-300 {
  font-size: var(--fs-300);
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--ff-heading);
}

p,
button,
a,
input,
label {
  font-family: var(--ff-body);
}

.px-large {
  padding-inline: 5rem;
}

.container {
  margin-inline: auto;
}

.input-search {
  color: var(--clr-primary-30);
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 23rem;
  border-radius: 0.75rem;
  padding: 1.5rem 1rem;
  border: 2px solid transparent;
}
.input-search:hover, .input-search:focus-within {
  border-color: var(--clr-primary-30);
}
.input-search > input {
  background-color: inherit;
  border: none;
}
.input-search > input:focus {
  outline: none;
  color: var(--clr-primary-10);
}

/* Customize the label (the container) */
.checkbox {
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  font-size: var(--fs-400);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0rem;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: inherit;
  border-radius: 0.25rem;
  border: 2px solid var(--clr-primary-30);
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  border-color: var(--clr-primary-10);
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: var(--clr-primary-30);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 0.35rem;
  top: 0.1rem;
  width: 0.5rem;
  height: 0.75rem;
  border: solid white;
  border-width: 0 0.2rem 0.2rem 0;
  transform: rotate(45deg);
}

.btn {
  border: 0;
  box-shadow: var(--shadow-md);
  background-color: var(--clr-neutral-90);
  color: var(--clr-primary-10);
  padding: 1rem 4rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: 999px;
  transition: 200ms ease opacity;
}
.btn:hover {
  opacity: 0.9;
}
.btn-primary {
  background-color: var(--clr-primary-30);
  color: var(--clr-neutral-100);
}
.btn-nav {
  flex-direction: column;
  font-size: var(--fs-300);
  padding: 1rem 2rem;
}
.btn-controller {
  font-size: var(--fs-300);
  box-shadow: none;
  background-color: inherit;
  padding: 0;
  border-radius: 0;
  border-right: 2px solid transparent;
}
.btn-controller:hover {
  border-color: var(--clr-neutral-80);
}
.btn-controller--active {
  border-color: var(--clr-primary-30);
  color: var(--clr-primary-30);
}

@media only screen and (min-width: 1024px) {
  .btn-nav {
    flex-direction: row;
    font-size: var(--fs-400);
    padding: 1rem 4rem;
  }
}
.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.card {
  width: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 1rem;
  background-color: var(--clr-accent-50);
  color: var(--clr-neutral-100);
}
.card-purple {
  background-color: var(--clr-purple);
}
.card-green {
  background-color: var(--clr-green);
}

.modal {
  display: flex;
  justify-content: end;
  align-items: start;
  position: absolute;
  z-index: 20;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  max-height: 0;
  overflow: hidden;
  transition: 500ms ease-out all;
}
.modal--open {
  max-height: 100vh;
}
.modal_container {
  width: 45rem;
  max-width: 70vw;
  min-height: 50vh;
  display: grid;
  gap: 1rem;
  overflow: hidden;
  background-color: var(--clr-neutral-100);
  border-radius: 1rem;
  margin: 9rem 5rem;
  padding: 2rem;
  overflow-y: scroll;
  height: 55vh;
}
.modal_container::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.modal_container::-webkit-scrollbar-thumb {
  background-color: var(--clr-primary-30);
  border-radius: 999px;
}
.modal_details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.modal_details-price {
  flex: 0 0 auto;
  min-width: 4rem;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  background-color: var(--clr-neutral-100);
  border: 2px solid var(--clr-accent-50);
  border-radius: 0.25rem;
}
.modal_details-icon {
  flex: 0 0 auto;
  width: 4rem;
  padding-right: 2rem;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  background-color: var(--clr-neutral-100);
  border-radius: 0.25rem;
}
.modal_details-icon > svg {
  width: 100%;
  height: 100%;
}
.modal_details-header {
  display: flex;
  gap: 1rem;
}
.modal_details-header-icon {
  align-items: center;
}
.modal_details-wrapper {
  display: grid;
  gap: 1.5rem;
  padding-bottom: 1rem;
}
.modal_details-heading {
  border-radius: 0.25rem;
  font-size: var(--fs-200);
  line-height: 1.75;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  background-color: #e8e7e3;
}

#map {
  width: 100vw;
  height: 90vh;
}

.controllers {
  pointer-events: none;
  z-index: 20;
  position: absolute;
  top: 2rem;
  left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.5rem;
}

.controllers_top {
  pointer-events: all;
  display: grid;
  gap: 1rem;
  border-radius: 1rem;
  background-color: var(--clr-neutral-100);
  box-shadow: var(--shadow-md);
  min-width: 25rem;
}

.controllers_middle {
  pointer-events: all;
  display: grid;
  gap: 1rem;
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  background-color: var(--clr-neutral-100);
  box-shadow: var(--shadow-md);
  min-width: 12.5rem;
}

.controllers_bottom {
  pointer-events: all;
  display: grid;
  gap: 1rem;
  padding: 1.5rem 1rem;
  background-color: var(--clr-neutral-100);
  border-radius: 1rem;
  box-shadow: var(--shadow-md);
  min-width: 12.5rem;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero_image {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: bottom;
     object-position: bottom;
  width: 100%;
  height: 60vh;
}
.hero_heading {
  max-width: 40rem;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-height: 85vh;
  overflow-y: scroll;
}

.info_left {
  min-width: 30rem;
  padding-top: 3.25rem;
  padding-inline: 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media only screen and (min-width: 1024px) {
  .info {
    flex-direction: row;
  }
  .info_left {
    max-width: 50%;
    padding-right: 0;
    overflow-y: scroll;
  }
  .info_left::-webkit-scrollbar {
    display: none;
  }
  .info_right {
    overflow-y: scroll;
  }
  .info_right::-webkit-scrollbar {
    display: none;
  }
}
.card_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.navigation {
  position: fixed;
  bottom: 6rem;
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.footer {
  position: fixed;
  bottom: 0rem;
  width: 100%;
  background-color: var(--clr-neutral-100);
  border-top: 1px solid var(--clr-neutral-80);
}
.footer_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 1rem;
}
.footer_image {
  max-width: 7.5rem;
}
.footer_buttons {
  display: flex;
  gap: 0.5rem;
}
.footer_btn {
  border: 0;
  background-color: inherit;
  font-weight: 500;
  color: var(--clr-primary-15);
  cursor: pointer;
  transition: opacity 200ms ease;
}
.footer_btn:hover {
  opacity: 0.5;
}
.footer_btn-active {
  border: 0;
  background-color: inherit;
  color: var(--clr-primary-30);
}/*# sourceMappingURL=main.css.map */